<template>
  <div class="pb-4" @mousewheel="closeActions()">
    <div class="d-flex justify-content-between mb-2">
      <h3>
        <span>
          {{ $t('billing.billingManagement.tab.billing') }}
        </span>
        <span>
          {{ formatMonth(month) }}
        </span>
      </h3>
    </div>
    <Table
      v-loading="eventsLoading"
      :columns="columns"
      :data="tableData"
      hover
      show-index
      rounded
      border
      custom-class="reconciliation-table"
      :expandable.sync="expandableRows"
      :cell-class="handleCellClass"
      @row-click="handleRowCLick"
    >
      <template #cell-status="{ rowData: { supplierStatus, statusOverride, supplier, reconciliationId }, rowIndex }">
        <ReconciliationStatus
          :is-admin="isAdmin"
          :reconciliation-id="reconciliationId"
          :reconciliation-status="supplierStatus"
          :status-override="statusOverride"
          :business-id="currentTenant.id"
          :supplier="supplier"
          :show="activeReconciliationStatus === rowIndex"
          @open="activeReconciliationStatus = rowIndex"
          @close="activeReconciliationStatus = -1"
        />
      </template>
      <template #cell-totalInvoices="{ rowData: { totalInvoices } }">
        <p>{{ totalInvoices ? totalInvoices : '-' }}</p>
      </template>
      <template #cell-totalUnbilled="{ rowData: { totalUnbilled } }">
        <p>{{ totalUnbilled ? totalUnbilled : '-' }}</p>
      </template>
      <template #filter-status>
        <small class="fw-bold">
          {{ $t('billing.billingManagement.billingTable.filterTitle') }}
        </small>
        <el-radio-group v-model="statusFilter" class="d-flex flex-column mt-2">
          <el-radio :label="STATUS_RADIO_FILTER.ALL" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.statusFilter.all') }}
            </p>
          </el-radio>
          <el-radio :label="STATUS_RADIO_FILTER.APPROVED" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.statusFilter.approved') }}
            </p>
          </el-radio>
          <el-radio :label="STATUS_RADIO_FILTER.PENDING" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.statusFilter.pending') }}
            </p>
          </el-radio>
          <el-radio :label="STATUS_RADIO_FILTER.NOT_APPROVED" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.statusFilter.notApproved') }}
            </p>
          </el-radio>
        </el-radio-group>
      </template>

      <template #cell-supplierReconciliation="{ rowData: { supplierReconciliation, reconciliationIds } }">
        <div v-if="supplierReconciliation" class="d-flex align-items-center gap-2">
          <div class="position-relative">
            <ADRIcon v-if="supplierReconciliation.type === RECONCILIATION_DOCUMENT_TYPES.AGED_DEBTORS_REPORT" />
            <RSIcon v-if="supplierReconciliation.type === RECONCILIATION_DOCUMENT_TYPES.RECONCILIATION_STATEMENT" />
            <UnknownDocumentIcon v-if="supplierReconciliation.type === RECONCILIATION_DOCUMENT_TYPES.UNKNOWN" />

            <span
              v-if="supplierReconciliation.status"
              class="position-absolute translate-middle p-1 rounded-circle center border border-2 border-white badge-position"
              :class="{ [`bg-${DOCUMENT_STATUS_COLOR[supplierReconciliation.status]}`]: true }"
            />
          </div>
          <Button
            v-if="supplierReconciliation.reconciliationDocument"
            type="link"
            class="text-typography-primary"
            @click.stop="
              $emit('open-document', {
                documentId: supplierReconciliation.reconciliationDocument.id,
                documentsIds: reconciliationIds,
              })
            "
          >
            {{ formatDate(supplierReconciliation.reconciliationDocument.issueDate) }}
          </Button>
        </div>
      </template>
      <template #filter-supplierReconciliation>
        <small class="fw-bold">
          {{ $t('billing.billingManagement.billingTable.filterTitle') }}
        </small>
        <el-radio-group v-model="supplierReconciliationFilter" class="d-flex flex-column mt-2">
          <el-radio :label="SUPPLIER_RECONCILIATION_RADIO_FILTER.ALL" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.reconciliationFilter.all') }}
            </p>
          </el-radio>
          <el-radio :label="SUPPLIER_RECONCILIATION_RADIO_FILTER.MISSING" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.reconciliationFilter.missing') }}
            </p>
          </el-radio>
          <el-radio :label="SUPPLIER_RECONCILIATION_RADIO_FILTER.PENDING" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.reconciliationFilter.pending') }}
            </p>
          </el-radio>
          <el-radio :label="SUPPLIER_RECONCILIATION_RADIO_FILTER.CHECKED" class="m-0 py-2">
            <p class="d-inline-flex text-typography-primary">
              {{ $t('billing.billingManagement.billingTable.reconciliationFilter.checked') }}
            </p>
          </el-radio>
        </el-radio-group>
      </template>
      <template #cell-totalAmount="{ rowData: { totalAmount } }">
        <p>{{ formatCentsToCurrency(totalAmount) ?? '-' }}</p>
      </template>
      <template
        #expandable-content="{
          rowData: {
            billings: billingsBySupplier,
            deliveries: deliveriesBySuppliers,
            orders: ordersBySupplier,
            missingEvents,
            supplierStatus,
          },
        }"
      >
        <ActivityData
          :loading="documentsLoading"
          :reconciliations-status="supplierStatus"
          :billings="billingsBySupplier"
          :deliveries="deliveriesBySuppliers"
          :missing="missingEvents"
          :orders="ordersBySupplier"
          :is-admin="isAdmin"
          @resolve-missing-event="$emit('resolve-missing-event', $event)"
          @open-document="$emit('open-document', $event)"
          @open-activity="$emit('open-activity', $event)"
          @open-issues="handleAction(ACTIONS.OPEN_ISSUES_MODAL, $event.supplierId, $event.billingId)"
          @open-credit-match="handleAction(ACTIONS.OPEN_CREDIT_MATCH_MODAL, $event.supplierId, $event.billingId)"
          @open-order-diffs-match="orderToValidate = $event"
        />
      </template>

      <template #cell-details="{ rowData: { details } }">
        <div
          v-for="(detail, index) in details"
          :key="`${index}-${detail.slice(0, 5)}`"
          :style="{ lineHeight: 1 }"
          class="my-1"
        >
          <span class="text-nowrap">
            {{ detail }}
          </span>
        </div>
      </template>

      <template
        #cell-actions="{
          rowData: {
            status,
            supplierId,
            supplierName,
            totalBilledAmount,
            totalPaidAmount,
            reconciliationId,
            reconciliationClosed,
            reconciliationPeriod,
            balanceAlignment,
            businessId,
            periodEnd,
            isDaily,
          },
          rowIndex,
        }"
      >
        <el-dropdown
          v-if="supplierId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="
            handleAction(
              $event,
              supplierId,
              null,
              supplierName,
              reconciliationId,
              reconciliationPeriod,
              reconciliationClosed,
              businessId,
              periodEnd,
              isDaily,
              balanceAlignment
            )
          "
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="text"
            class="p-1 actions-btn text-typography-primary"
            :class="{ active: activeActions === rowIndex }"
            @click.stop="handleOuterClick"
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item
              :disabled="status === RECONCILIATION_STATUSES.APPROVED"
              :command="ACTIONS.OPEN_ISSUES_MODAL"
            >
              <div class="d-flex align-items-center gap-2">
                <AccountingIcon />
                {{ $t('billing.billingManagement.billingTable.actions.openIssuesModal') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="ACTIONS.OPEN_CREDIT_MATCH_MODAL">
              <div class="d-flex align-items-center gap-2">
                <DocumentMatchingIcon />{{
                  $t('billing.billingManagement.billingTable.actions.openCreditMatchModalData')
                }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided :command="ACTIONS.OPEN_EVENT_MAP">
              <div class="d-flex align-items-center gap-2">
                <OngoingActivityIcon /> {{ $t('billing.billingManagement.billingTable.actions.openEventMap') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="ACTIONS.OPEN_RECONCILIATION_REPORT">
              <div class="d-flex align-items-center gap-2">
                <ReconciliationIcon /> {{ $t('billing.billingManagement.billingTable.actions.transactions') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item :command="ACTIONS.OPEN_AGED_DEBTORS_REPORT">
              <div class="d-flex align-items-center gap-2">
                <DebtIcon /> {{ $t('billing.billingManagement.billingTable.actions.agedDebtorsReport') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item v-if="isAdmin" divided :command="ACTIONS.OPEN_RECONCILIATION_SETTINGS">
              <div class="d-flex align-items-center gap-2">
                <SettingsIcon /> {{ $t('billing.billingManagement.billingTable.actions.singleReconciliationSettings') }}
                <Tag v-if="!supplierTemplateDefined(supplierId)">
                  {{ $t('billing.billingManagement.billingTable.actions.default') }}
                </Tag>
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              divided
              :disabled="!isAdmin || !balanceAlignment.required || balanceAlignment.validated"
              :command="ACTIONS.VALIDATE_BALANCE_ALIGNMENT"
            >
              <div class="d-flex align-items-center gap-2">
                <CheckCircleIcon />
                {{ $t('billing.billingManagement.billingTable.actions.validateBalanceAlignment') }}
              </div>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isAdmin"
              :disabled="totalBilledAmount !== totalPaidAmount || reconciliationClosed"
              :command="ACTIONS.CLOSE_RECONCILIATION"
            >
              <div
                class="d-flex align-items-center gap-2 text-typography-primary"
                :class="`${
                  totalBilledAmount !== totalPaidAmount || reconciliationClosed
                    ? 'text-disabled'
                    : 'text-typography-primary'
                }`"
              >
                <ChequeredFlagIcon />
                <p>{{ $t('payment.paymentTable.singleRowActions.closeReconciliation') }}</p>
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided :command="ACTIONS.OPEN_CHAT">
              <div class="d-flex align-items-center gap-2">
                <ChatIcon />
                {{ $t('billing.billingManagement.billingTable.actions.openChat') }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template
        #cell-chat="{ rowData: { supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily } }"
      >
        <template v-if="supplier && supplier.id && channelUnreadMessagesCount[reconciliationId] >= 0">
          <Button
            class="p-1 hover-btn text-typography-primary d-flex position-relative"
            type="text"
            @click.stop="
              $emit('chat-open', {
                supplier,
                reconciliationId,
                reconciliationPeriod,
                reconciliationClosed,
                isDaily,
              })
            "
          >
            <ChatIcon />
            <span
              v-if="channelUnreadMessagesCount[reconciliationId] > 0"
              class="badge rounded-pill top-0 bg-danger position-absolute border border-2 border-white"
              :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
            >
              {{
                channelUnreadMessagesCount[reconciliationId] > 99 ? '+99' : channelUnreadMessagesCount[reconciliationId]
              }}
            </span>
          </Button>
        </template>
      </template>
    </Table>
    <IssuesModal
      v-if="issueModalData"
      :month="issueModalData && issueModalData.month"
      :supplier-id="issueModalData && issueModalData.supplierId"
      :business-id="currentTenant.id"
      :billing-id="issueModalData && issueModalData.billingId"
      @close="issueModalData = null"
    />
    <CreditMatchModal
      v-if="creditMatchModalData"
      :selected-billing-id="creditMatchModalData && creditMatchModalData.billingId"
      :default-month="creditMatchModalData && creditMatchModalData.month"
      :supplier="creditMatchModalData && creditMatchModalData.supplier"
      :business-id="currentTenant.id"
      @close="creditMatchModalData = null"
    />
    <OrderDifferenceMatchModal
      v-if="orderToValidate"
      :default-month="month"
      :supplier="suppliers.find((supplier) => supplier.id === orderToValidate.supplierId)"
      :business="currentTenant"
      :order="orderToValidate"
      @close="orderToValidate = null"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="suppliers.find((supplier) => supplier.id === reconciliationTemplateParams.supplierId)"
      :business="currentTenant"
      :reconciliation-template="reconciliationTemplateParams.reconciliationTemplate"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleSaveReconciliationTemplate(params)"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      ref="reconciliationModal"
      :reconciliation-id="pickedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="$emit('chat-open', $event)"
    />
    <BalanceAlignmentModal
      v-if="balanceAlignmentReconciliation"
      :reconciliation="balanceAlignmentReconciliation"
      @close="balanceAlignmentReconciliation = null"
      @validate="validateBalanceAlignment"
    />
  </div>
</template>

<script>
import { groupBy, isEmpty } from 'ramda';
import { ref, computed } from 'vue';

import { options } from '@/locale/dateConfig';
import { Table, Button, Tag } from '@/modules/core';
import {
  KebabIcon,
  ChatIcon,
  ReconciliationIcon,
  ChequeredFlagIcon,
  DebtIcon,
  AccountingIcon,
  DocumentMatchingIcon,
  OngoingActivityIcon,
  ADRIcon,
  RSIcon,
  CheckCircleIcon,
  UnknownDocumentIcon,
  SettingsIcon,
} from '@/assets/icons';
import { RECONCILIATION_STATUSES, useChannel } from '@/modules/reconciliation';
import { IssuesModal } from '@/modules/modals';
import { CreditMatchModal, OrderDifferenceMatchModal } from '@/modules/activity';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import ActivityData from './Activity';

const groupDocumentsBySupplier = groupBy((document) => document.supplierId);
const groupMissingEventsBySupplier = groupBy((missingEvent) => missingEvent.supplierId);

const TABLE_HEADER = {
  SUPPLIER_NAME: 'supplierName',
  TOTAL_INVOICES: 'totalInvoices',
  TOTAL_UNBILLED: 'totalUnbilled',
  TOTAL_AMOUNT: 'totalAmount',
  PAYMENT_DUE_DATE: 'paymentDueDate',
  STATUS: 'status',
  SUPPLIER_RECONCILIATION: 'supplierReconciliation',
  DETAILS: 'details',
  CHAT: 'chat',
  ACTIONS: 'actions',
};

const MISSING_SUPPLIER_RECONCILIATION = 'missing';

const RECONCILIATION_DOCUMENT_TYPES = {
  RECONCILIATION_STATEMENT: 'reconciliationStatement',
  AGED_DEBTORS_REPORT: 'agedDebtorsReport',
  UNKNOWN: 'unknown',
};

const DOCUMENT_STATUSES = {
  PENDING: 'pending',
  CHECKED: 'checked',
  MISSING: 'missing',
};

const SUPPLIER_RECONCILIATION_RADIO_FILTER = {
  ALL: 'all',
  ...DOCUMENT_STATUSES,
};

const DOCUMENT_STATUS_COLOR = {
  [DOCUMENT_STATUSES.PENDING]: 'disabled',
  [DOCUMENT_STATUSES.CHECKED]: 'success',
  [DOCUMENT_STATUSES.MISSING]: 'warning',
};

const STATUS_RADIO_FILTER = {
  ALL: 'all',
  NOT_APPROVED: 'notApproved',
  PENDING: 'pending',
  APPROVED: 'approved',
};

const ACTIONS = {
  OPEN_ISSUES_MODAL: 'issuesModal',
  OPEN_CREDIT_MATCH_MODAL: 'creditMatchModal',
  OPEN_EVENT_MAP: 'openEventMap',
  OPEN_RECONCILIATION_REPORT: 'openReconciliationStatementReport',
  OPEN_AGED_DEBTORS_REPORT: 'openAgedDebtorsReport',
  OPEN_RECONCILIATION_SETTINGS: 'openReconciliationSettings',
  CLOSE_RECONCILIATION: 'closeReconciliation',
  OPEN_CHAT: 'openChat',
  VALIDATE_BALANCE_ALIGNMENT: 'validateBalanceAlignment',
};

const getScoreForSupplierReconciliation = ({ supplierReconciliation }) => {
  if (!supplierReconciliation) return 1;
  if (supplierReconciliation.status === DOCUMENT_STATUSES.MISSING) return 5;
  if (supplierReconciliation.status === DOCUMENT_STATUSES.PENDING) return 4;
  if (supplierReconciliation.status === DOCUMENT_STATUSES.CHECKED) return 3;
  if (supplierReconciliation.reconciliationDocument) return 2;
};

const getSort = ({ direction, column }) => {
  const { SUPPLIER_NAME, TOTAL_INVOICES, TOTAL_UNBILLED, TOTAL_AMOUNT, PAYMENT_DUE_DATE, SUPPLIER_RECONCILIATION } =
    TABLE_HEADER;
  switch (column) {
    case SUPPLIER_NAME:
      return (a, b) => a[SUPPLIER_NAME].localeCompare(b[SUPPLIER_NAME]) * direction;

    case TOTAL_INVOICES:
      return (a, b) => (a[TOTAL_INVOICES] - b[TOTAL_INVOICES]) * direction;

    case TOTAL_UNBILLED:
      return (a, b) => (a[TOTAL_UNBILLED] - b[TOTAL_UNBILLED]) * direction;

    case TOTAL_AMOUNT:
      return (a, b) => (a[TOTAL_AMOUNT] - b[TOTAL_AMOUNT]) * direction;

    case PAYMENT_DUE_DATE:
      return (a, b) => (a[PAYMENT_DUE_DATE] - b[PAYMENT_DUE_DATE]) * direction;

    case SUPPLIER_RECONCILIATION:
      return (a, b) => (getScoreForSupplierReconciliation(a) - getScoreForSupplierReconciliation(b)) * direction;

    default:
      return () => 0;
  }
};

const getSupplierReconciliation = (reconciliationDocuments) => {
  const supplierReconciliation = {};
  if (reconciliationDocuments.length) {
    supplierReconciliation.reconciliationDocument = reconciliationDocuments[0];
    supplierReconciliation.type = reconciliationDocuments[0].type;
  }
  return isEmpty(supplierReconciliation) ? null : supplierReconciliation;
};

export default {
  components: {
    Table,
    ActivityData,
    KebabIcon,
    Button,
    IssuesModal,
    ChatIcon,
    CreditMatchModal,
    ChequeredFlagIcon,
    ReconciliationIcon,
    AccountingIcon,
    DocumentMatchingIcon,
    DebtIcon,
    OngoingActivityIcon,
    ADRIcon,
    CheckCircleIcon,
    RSIcon,
    UnknownDocumentIcon,
    SettingsIcon,
    Tag,
    OrderDifferenceMatchModal,
    ReconciliationSettingsModal: () =>
      import('@/modules/reconciliation/reconciliationSettingsModal/ReconciliationSettingsModal'),
    ReconciliationModal: () => import('@/modules/reconciliation/reconciliationModal/ReconciliationModal'),
    ReconciliationStatus: () => import('@/modules/reconciliation/reconciliationStatus/ReconciliationStatus'),
    BalanceAlignmentModal: () => import('@/modules/reconciliation/components/BalanceAlignmentModal'),
  },
  props: {
    eventsLoading: { type: Boolean, default: false },
    isAdmin: { type: Boolean, required: true },
    documentsLoading: { type: Boolean, default: false },
    month: { type: String, required: true },
    events: { type: Object, required: true },
    documents: { type: Array, required: true },
    reconciliationDocuments: { type: Array, required: true },
    missing: { type: Array, required: true },
    suppliers: { type: Array, required: true },
    productsLoading: { type: Boolean, default: true },
    customer: { type: Object, required: true },
    orderDifferences: { type: Array, default: () => [] },
    deliveryDifferences: { type: Array, default: () => [] },
    reconciliations: { type: Array, required: true },
    reconciliationTemplates: { type: Object, required: true },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();
    const reconciliationIds = computed(() => {
      return props.reconciliations.map(({ id }) => id);
    });

    const { channelUnreadMessagesCount } = useChannel(reconciliationIds);

    return {
      formatCentsToCurrency,
      currentTenant,
      channelUnreadMessagesCount,
      activeMissingReconciliationTaskPopover: ref(-1),
      activeReconciliationStatus: ref(-1),
      pickedReconciliationId: ref(null),
      templates: computed(() => props.reconciliationTemplates),
      balanceAlignmentReconciliation: ref(null),
      activeSort: ref({ direction: 1, column: TABLE_HEADER.SUPPLIER_NAME }),
      documentId: ref(null),
      activity: ref(null),
      expandableRows: ref({}),
      reconciliationTemplateParams: ref(null),
      activeActions: ref(null),
      issueModalData: ref(null),
      creditMatchModalData: ref(null),
      orderToValidate: ref(null),
      statusFilter: ref(STATUS_RADIO_FILTER.ALL),
      supplierReconciliationFilter: ref(SUPPLIER_RECONCILIATION_RADIO_FILTER.ALL),
      ACTIONS,
      RECONCILIATION_STATUSES,
      MISSING_SUPPLIER_RECONCILIATION,
      RECONCILIATION_DOCUMENT_TYPES,
      DOCUMENT_STATUSES,
      DOCUMENT_STATUS_COLOR,
      SUPPLIER_RECONCILIATION_RADIO_FILTER,
      STATUS_RADIO_FILTER,
    };
  },
  computed: {
    billings() {
      return this.events.billings?.map(this.setDocumentsFromEventReferences) ?? [];
    },
    deliveries() {
      return (
        [...(this.events.deliveries || []), ...this.deliveryDifferences].map(this.setDocumentsFromEventReferences) ?? []
      );
    },
    orders() {
      return [...(this.events.orders || []), ...this.orderDifferences].map(this.setDocumentsFromEventReferences) ?? [];
    },
    tableData() {
      const billingsBySupplier = groupDocumentsBySupplier(this.billings);
      const deliveriesBySupplier = groupDocumentsBySupplier(this.deliveries);
      const ordersBySupplier = groupDocumentsBySupplier(this.orders);
      const missingEventsBySupplier = groupMissingEventsBySupplier(this.missing);
      const reconciliationsBySupplier = groupDocumentsBySupplier(this.reconciliationDocuments);

      const dataBySupplier = this.reconciliations.map(
        ({
          supplierId,
          businessId,
          billedAmounts,
          paidAmounts,
          id,
          closed,
          periodStart,
          periodEnd,
          balanceAlignment,
          status,
          statusOverride,
        }) => ({
          supplier: this.suppliers.find(({ id }) => id === supplierId),
          billings: billingsBySupplier[supplierId] ?? [],
          deliveries: deliveriesBySupplier[supplierId] ?? [],
          orders: ordersBySupplier[supplierId] ?? [],
          missingEvents: missingEventsBySupplier[supplierId] ?? [],
          reconciliationDocuments: (reconciliationsBySupplier[supplierId] ?? []).sort(
            (a, b) => new Date(b.issueDate) - new Date(a.issueDate)
          ),
          totalBilledAmount: billedAmounts.reduce((prev, { amount }) => prev + amount, 0).toFixed(2),
          totalPaidAmount: paidAmounts.reduce((prev, { amount }) => prev + amount, 0).toFixed(2),
          reconciliationId: id,
          reconciliationClosed: closed,
          reconciliationPeriod: periodStart,
          balanceAlignment,
          periodEnd,
          isDaily: periodStart === periodEnd,
          businessId,
          supplierStatus: status,
          statusOverride,
        })
      );

      return dataBySupplier
        .map(
          ({
            supplier,
            billings,
            deliveries,
            orders,
            reconciliationDocuments,
            missingEvents,
            totalBilledAmount,
            totalPaidAmount,
            reconciliationId,
            reconciliationClosed,
            reconciliationPeriod,
            balanceAlignment,
            periodEnd,
            isDaily,
            businessId,
            supplierStatus,
            statusOverride,
          }) => {
            const deliveriesIdsWithBillings = billings.reduce(
              (ids, { deliveryRefs }) => ({
                ...ids,
                ...deliveryRefs.reduce((deliveryIds, ref) => ({ ...deliveryIds, [ref.delivery?.id]: true }), {}),
              }),
              {}
            );

            const unbilledDeliveries = deliveries.filter((delivery) => !deliveriesIdsWithBillings[delivery.id]);

            return {
              [TABLE_HEADER.SUPPLIER_NAME]: supplier?.name ?? '-',
              [TABLE_HEADER.TOTAL_INVOICES]: billings.length,
              [TABLE_HEADER.TOTAL_UNBILLED]: unbilledDeliveries.length,
              [TABLE_HEADER.TOTAL_AMOUNT]: billings.length ? billings.reduce((acc, b) => acc + b.totalAmount, 0) : null,
              [TABLE_HEADER.PAYMENT_DUE_DATE]: '-',
              [TABLE_HEADER.SUPPLIER_RECONCILIATION]: getSupplierReconciliation(reconciliationDocuments),
              [TABLE_HEADER.DETAILS]: this.getDetails(billings, deliveries, orders, missingEvents),
              supplierId: supplier?.id,
              supplier: supplier,
              billings,
              deliveries,
              orders,
              missingEvents,
              expandable: true,
              expandableCustomClass: 'p-0',
              reconciliationIds: reconciliationDocuments.map(({ id }) => id),
              totalBilledAmount,
              totalPaidAmount,
              reconciliationId,
              reconciliationClosed,
              reconciliationPeriod,
              supplierStatus,
              statusOverride,
              balanceAlignment,
              periodEnd,
              isDaily,
              businessId,
            };
          }
        )
        .filter(this.getSupplierReconciliationFilter)
        .filter(this.getStatusFilter)
        .sort(getSort(this.activeSort));
    },
    columns() {
      return [
        {
          header: this.$t('billing.billingManagement.commonTable.supplierName'),
          key: TABLE_HEADER.SUPPLIER_NAME,
          width: '150px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.SUPPLIER_NAME, direction }),
        },
        {
          header: this.$t('billing.billingManagement.commonTable.totalInvoices'),
          key: TABLE_HEADER.TOTAL_INVOICES,
          width: '70px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.TOTAL_INVOICES, direction }),
        },
        {
          header: this.$t('billing.billingManagement.commonTable.totalUnbilled'),
          key: TABLE_HEADER.TOTAL_UNBILLED,
          width: '90px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.TOTAL_UNBILLED, direction }),
        },
        {
          header: this.$t('billing.billingManagement.commonTable.totalAmount'),
          key: TABLE_HEADER.TOTAL_AMOUNT,
          width: '150px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.TOTAL_AMOUNT, direction }),
        },
        {
          header: this.$t('billing.billingManagement.commonTable.paymentDueDate'),
          key: TABLE_HEADER.PAYMENT_DUE_DATE,
          width: '140px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.PAYMENT_DUE_DATE, direction }),
        },
        {
          header: this.$t('billing.billingManagement.commonTable.supplierReconciliation'),
          key: TABLE_HEADER.SUPPLIER_RECONCILIATION,
          width: '150px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.SUPPLIER_RECONCILIATION, direction }),
          filterActive: this.supplierReconciliationFilter !== SUPPLIER_RECONCILIATION_RADIO_FILTER.ALL,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.status'),
          key: TABLE_HEADER.STATUS,
          width: '135px',
          filterActive: this.statusFilter !== STATUS_RADIO_FILTER.ALL,
        },
        {
          header: this.$t('billing.billingManagement.commonTable.details'),
          key: TABLE_HEADER.DETAILS,
          width: '200px',
        },
        {
          header: '',
          key: TABLE_HEADER.CHAT,
          width: '34px',
          customClass: 'p-0',
        },
        {
          header: '',
          key: TABLE_HEADER.ACTIONS,
          width: '40px',
          customClass: 'p-0',
        },
      ];
    },
  },
  watch: {
    tableData() {
      this.activeReconciliationStatus = -1;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOuterClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOuterClick);
  },
  methods: {
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.$emit('chat-close');
    },
    handleSaveReconciliationTemplate(params) {
      !this.templates.data.some((reco) => reco.supplierId === this.reconciliationTemplateParams.supplierId)
        ? this.handleReconciliationParamsCreate(params)
        : this.handleReconciliationParamsUpdate(params);
    },
    formatDateShort(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: 'numeric',
          })
        : '';
    },
    handleReconciliationParamsCreate(params) {
      this.$emit('create-reconciliation-template', params);
      this.reconciliationTemplateParams = null;
    },
    handleReconciliationParamsUpdate(params) {
      this.$emit('update-reconciliation-template', params);
      this.reconciliationTemplateParams = null;
    },
    supplierTemplateDefined(currSupplierId) {
      return this.templates.data?.some(({ supplierId }) => supplierId === currSupplierId);
    },
    handleOuterClick() {
      this.activeMissingReconciliationTaskPopover = -1;
    },
    formatMonth(month) {
      return new Date(month).toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' });
    },
    setDocumentsFromEventReferences(event) {
      const eventDocRefs = event.eventReferences
        ? event.eventReferences.map(({ documentId }) => documentId)
        : [event.source?.ref];
      const documents = this.documents.filter(({ id }) => eventDocRefs.includes(id));
      if (documents.length)
        return {
          ...event,
          documents: documents.map((document) => ({
            id: document.id,
            type: document.type,
            documentNumber: document.documentNumber,
          })),
        };

      return event;
    },
    getSupplierReconciliationFilter(row) {
      if (this.supplierReconciliationFilter === SUPPLIER_RECONCILIATION_RADIO_FILTER.CHECKED)
        return row.supplierReconciliation?.status === DOCUMENT_STATUSES.CHECKED || !row.supplierReconciliation?.status;
      if (this.supplierReconciliationFilter === SUPPLIER_RECONCILIATION_RADIO_FILTER.MISSING)
        return row.supplierReconciliation?.status === DOCUMENT_STATUSES.MISSING;
      if (this.supplierReconciliationFilter === SUPPLIER_RECONCILIATION_RADIO_FILTER.PENDING)
        return row.supplierReconciliation?.status === DOCUMENT_STATUSES.PENDING;
      return true;
    },
    getStatusFilter(row) {
      const status = row.statusOverride?.status ?? row.supplierStatus?.status;
      if (this.statusFilter === STATUS_RADIO_FILTER.APPROVED) return status === STATUS_RADIO_FILTER.APPROVED;
      if (this.statusFilter === STATUS_RADIO_FILTER.NOT_APPROVED) return status === STATUS_RADIO_FILTER.NOT_APPROVED;
      if (this.statusFilter === STATUS_RADIO_FILTER.PENDING) return status === STATUS_RADIO_FILTER.PENDING;
      return true;
    },
    getDetails(billings, deliveries, orders, missingEvents) {
      const texts = [];
      if (!billings.length && !deliveries.length && !orders.length) return null;

      if (billings.length) {
        const ordersCount = billings.reduce((orderCount, billing) => orderCount + billing.orderLinks.length, 0);

        if (billings.some((b) => b.imbalances.some(({ resolved }) => !resolved) && b.totalAmount >= 0))
          texts.push(this.$tc('billing.billingManagement.details.imbalance', ordersCount));
        if (billings.some((b) => b.imbalances.some(({ resolved }) => !resolved) && b.totalAmount < 0))
          texts.push(this.$t('billing.billingManagement.details.notMatched'));
      } else if (deliveries.length || orders.length) {
        const deliveriesHasImbalancesOrDifferences = deliveries.some((d) => d.imbalances.length || d.diffs?.length);
        const ordersHasDifferences = orders.some((o) => o.differences?.length);
        if (deliveriesHasImbalancesOrDifferences || ordersHasDifferences)
          texts.push(this.$tc('billing.billingManagement.details.imbalance', deliveries.length));
      }

      if (missingEvents.length)
        texts.push(
          this.$tc('billing.billingManagement.details.missing', missingEvents.length, {
            count: missingEvents.length,
          })
        );

      return texts.length ? texts : null;
    },
    handleRowCLick(rowIndex) {
      this.pickedReconciliationId = this.tableData[rowIndex].reconciliationId;
      this.$emit('row-click', { supplierId: this.tableData[rowIndex].supplierId });
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    async handleAction(
      command,
      supplierId,
      billingId,
      supplierName,
      reconciliationId,
      reconciliationPeriod,
      reconciliationClosed,
      businessId,
      periodEnd,
      isDaily,
      balanceAlignment
    ) {
      switch (command) {
        case ACTIONS.OPEN_ISSUES_MODAL:
          this.issueModalData = {
            supplierId,
            month: this.month,
            billingId,
          };
          break;
        case ACTIONS.OPEN_CREDIT_MATCH_MODAL:
          this.creditMatchModalData = {
            supplier: this.suppliers.find((supplier) => supplier.id === supplierId),
            month: this.month,
            billingId,
          };
          break;
        case ACTIONS.OPEN_EVENT_MAP:
          this.$emit('open-event-map', supplierId);
          break;
        case ACTIONS.OPEN_RECONCILIATION_REPORT:
          this.routeToReconciliationStatementReport(supplierId);
          break;
        case ACTIONS.OPEN_AGED_DEBTORS_REPORT:
          this.routeToAgedDebtorsReport(supplierId);
          break;
        case ACTIONS.OPEN_RECONCILIATION_SETTINGS:
          this.reconciliationTemplateParams = {
            supplierId,
            reconciliationTemplate:
              this.templates.data.find((reco) => reco.supplierId === supplierId) ?? this.templates.default,
          };
          break;
        case ACTIONS.VALIDATE_BALANCE_ALIGNMENT:
          this.balanceAlignmentReconciliation = {
            id: reconciliationId,
            businessId,
            supplierId,
            periodEnd,
            balanceAlignment,
          };
          break;
        case ACTIONS.CLOSE_RECONCILIATION:
          {
            try {
              await this.$confirm(
                this.$t('payment.paymentTable.reconciliationCloseConfirm.text', {
                  date: this.formatDateShort(reconciliationPeriod),
                  client: supplierName,
                }),
                this.$t('payment.paymentTable.reconciliationCloseConfirm.title'),
                {
                  showClose: false,
                  confirmButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.confirm'),
                  cancelButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.cancel'),
                  confirmButtonClass: 'el-button--danger',
                  cancelButtonClass: 'el-button--secondary',
                }
              );
              this.$emit('close-reconciliation', reconciliationId);
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }

          break;
        case ACTIONS.OPEN_CHAT:
          {
            const supplier = this.suppliers.find((supplier) => supplier.id === supplierId);
            this.$emit('chat-open', {
              supplier,
              reconciliationId,
              reconciliationPeriod,
              reconciliationClosed,
              isDaily,
            });
          }
          break;
        default:
          break;
      }
    },
    closeActions() {
      if (this.activeActions !== null) {
        document.getElementById(`actions-row-${this.activeActions}`).click();
        this.activeActions = null;
      }
      if (this.activeMissingReconciliationTaskPopover !== -1) this.activeMissingReconciliationTaskPopover = -1;
    },
    routeToReconciliationStatementReport(supplierId) {
      const route = this.$router.resolve({
        name: 'reconciliationStatementReport',
        params: { supplierId },
      });
      window.open(route.href, '_blank');
    },
    routeToAgedDebtorsReport(supplierId) {
      const route = this.$router.resolve({
        name: 'agedDebtorsReport',
        params: { supplierId },
      });
      window.open(route.href, '_blank');
    },
    handleCellClass(rowIndex) {
      if (this.activeActions === rowIndex || this.activeReconciliationStatus === rowIndex) return 'bg-secondary';
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    validateBalanceAlignment(event) {
      this.balanceAlignmentReconciliation = null;
      this.$emit('validate-balance-alignment', event);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.reconciliation-table {
  .actions-btn {
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  .hover-btn {
    &:hover {
      background: $secondary;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

.secondary-hover-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}

.badge-position {
  top: 15%;
  left: 10%;
}
</style>
